<template>
  <!-- 转发设置抽屉 -->
  <a-drawer
    :title="name"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-alert message="用户自行转发时，将读取下方设置。若不设置，将调用剧名和剧集封面图，会降低转发效果。" type="info" show-icon />
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      style="margin-top: 24px;"
    >
      <!-- 小程序卡片标题 -->
      <a-form-item
        label="小程序卡片标题"
        name="wxapp_title"
        :rules="[{ required: false, validator: validator }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.wxapp_title"
          :maxlength="20"
        />
      </a-form-item>
      <!-- 小程序卡片封面 -->
      <a-form-item
        label="小程序卡片封面"
        name="wxapp_cover"
        :rules="[{ required: false, validator: validator }]"
      >
        <!-- 上传组件 -->
        <a-form-item-rest>
          <Upload
            accept=".png,.jpg,.gif"
            uploadSource="BaseInfo"
            :fileNumber="5"
            :imgSizeMode="22"
            :imgSizeWidth="624"
            :imgSizeHeight="520"
            imgSizeError="图片最小尺寸为 624 * 520"
            :imgScaleMode="1"
            :imgScaleWidth="624"
            :imgScaleHeight="520"
            imgScaleError="图片尺寸比例不正确"
            :beforeUploadPro="beforeUploadPro"
            :fileList="formState.wxapp_cover"
            :uploadBucket="$pub.BUCKET_PUB()"
          />
        </a-form-item-rest>
        <div class="form-upload-hint">支持.png .jpg .gif，624x520，或尺寸更大的等比图片</div>
        <div class="form-upload-list" v-if="formState.wxapp_cover.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.wxapp_cover"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover('wxapp_cover', index)"
          >
            <a-form-item class="checkbox-form">
              <a-checkbox
                v-model:checked="item.is_choose"
                class="upload-item-select"
                @change="changeSelectCover('wxapp_cover', index)"
              >
              </a-checkbox>
            </a-form-item>
          </ImageView>
        </div>
      </a-form-item>
      <!-- H5标题 -->
      <a-form-item
        label="H5标题"
        name="h5_title"
        :rules="[{ required: false, validator: validator }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.h5_title"
          :maxlength="20"
        />
      </a-form-item>
      <!-- H5描述 -->
      <a-form-item
        label="H5描述"
        name="h5_desc"
        :rules="[{ required: false, validator: validator }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.h5_desc"
          :maxlength="20"
        />
      </a-form-item>
      <!-- H5缩略图 -->
      <a-form-item
        label="H5缩略图"
        name="h5_cover"
        :rules="[{ required: false, validator: validator }]"
      >
        <a-form-item-rest>
          <Upload
            accept=".png,.jpg,.gif"
            uploadSource="BaseInfo"
            :fileNumber="5"
            :imgSizeMode="22"
            :imgSizeWidth="144"
            :imgSizeHeight="192"
            imgSizeError="图片最小尺寸为 144 * 192"
            :imgScaleMode="1"
            :imgScaleWidth="144"
            :imgScaleHeight="192"
            imgScaleError="图片尺寸比例不正确"
            :beforeUploadPro="beforeUploadPro"
            :fileList="formState.h5_cover"
            :uploadBucket="$pub.BUCKET_PUB()"
          />
        </a-form-item-rest>
        
        <div class="form-upload-hint">支持.png .jpg .gif，144×192，或尺寸更大的等比图片</div>
        <div class="form-upload-list" v-if="formState.h5_cover.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.h5_cover"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover('h5_cover', index)"
          >
            <a-form-item class="checkbox-form">
              <a-checkbox
                v-model:checked="item.is_choose"
                class="upload-item-select"
                @change="changeSelectCover('h5_cover', index)"
              >
              </a-checkbox>
            </a-form-item>
          </ImageView>
        </div>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import Upload from '@/components/Upload'
import ImageView from '@/components/ImageView'
import Pub from '@/utils/public'
import { qyMessageDetail, qyMessageSetting } from '@/api/operate'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 片库ID、name
let id = ref(undefined)
let name = ref(undefined)
// 剧集详情
// let detail = ref({})
// 剧集信息
let formState = reactive({
  // 小程序卡片标题
  wxapp_title: undefined,
  // 小程序卡片封面
  wxapp_cover: [],
  // H5标题
  h5_title: undefined,
  // H5描述
  h5_desc: undefined,
  // H5缩略图
  h5_cover: [],
})

// 打开抽屉
function showDrawer (record) {
  visible.value = true
  id.value = record.id
  name.value = record.name
  // 获取详情
  getDetail()
}

// 获取详情
function getDetail () {
  isLoading.value = true
  qyMessageDetail({
    id: id.value
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 有配置
      if (data.qy_remark) {
        // 表单数据
        const info = data.qy_remark
        // 小程序卡片标题
        formState.wxapp_cover = (info.wxapp_cover || []).map(item => {
          item.is_choose = item.is_choose ? true : false
          return item
        })
        // 小程序卡片封面
        formState.h5_cover = (info.h5_cover || []).map(item => {
          item.is_choose = item.is_choose ? true : false
          return item
        })
        // 小程序卡片标题
        formState.wxapp_title = info.wxapp_title
        // H5标题
        formState.h5_title = info.h5_title
        // H5描述
        formState.h5_desc = info.h5_desc
      }
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    RefForm.value.resetFields()
    visible.value = false
  }
}

// 准备上传（图片）
function beforeUploadPro (file) {
  if (!file.type.includes('image')) {
    message.error('请上传图片类型的文件')
    return false
  }
  return true
}

// 表单校验
function validator (rule, value) {
  // 封面 || 缩略图
  if (rule.field === 'wxapp_cover' || rule.field === 'h5_cover') {
    if (value && value.length === 0) {
      return Promise.resolve()
    }
    if (!value.some(item => item.is_choose)) {
      return Promise.reject('必须选中一个封面')
    }
    return Promise.resolve()
  }
  return Promise.resolve()
}

// 移除封面
function touchDeleteCover (coverKey, index) {
  formState[coverKey].splice(index, 1)
}
// 处理封面、视频集锦为单选
function changeSelectCover (coverKey, index) {
  const bool = formState[coverKey][index].is_choose
  if (bool) {
    formState[coverKey].forEach((item, i) => {
      if (i !== index) {
        item.is_choose = false
      }
    })
  }
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then((() => {
    isLoading.value = true
    // 根据布尔值处理封面选中
    formState.wxapp_cover.forEach(item => {
      item.is_choose = item.is_choose ? 1 : 0
    })
    formState.h5_cover.forEach(item => {
      item.is_choose = item.is_choose ? 1 : 0
    })
    // 准备数据
    const params = {
      id: id.value,
      qy_remark: {
        ...formState
      }
    }
    qyMessageSetting(params).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    }).catch(() => {
      isLoading.value = false
      message.error('操作失败')
    })
  }))
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  width: 148px;
  height: 118px;
}
.checkbox-form {
  position: absolute;
  right: 8px;
  bottom: 18px;
  width: 14px;
  height: 14px;
  margin-bottom: 0;
}
.upload-item-select {
  
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
</style>